/*  eslint-disable */
import { useEffect } from "react"
import { gql, useQuery, useMutation } from "src/apolloClient"

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    user @rest(type: "User", path: "/me") {
      id
      email
      display_name
      email
      company_size
      contact_number

      first_name
      last_name
      active
    }
  }
`
// industry

const useCurrentUser = () => {
  const { data, loading } = useQuery(GET_CURRENT_USER)

  const user = data && data.user

  const mutationBaseOptions = {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  }

  const [register] = useMutation(
    gql`
      mutation register($input: UserRegisterInput) {
        user(input: $input)
          @rest(type: "User", path: "/auth/signup", method: "POST") {
          id
          first_name
          last_name
          display_name
          company_size
          email
          contact_number
          active
          organizationUuid
        }
      }
    `,
    mutationBaseOptions
  )

  const [update] = useMutation(
    gql`
      mutation updateUser($input: UpdateUserInput) {
        user(input: $input) @rest(type: "User", path: "/me", method: "PATCH") {
          email
        }
      }
    `,
    mutationBaseOptions
  )

  const [logout] = useMutation(
    gql`
      mutation updateUser {
        user @rest(path: "/auth/logout", method: "GET") {
          email
        }
      }
    `,
    mutationBaseOptions
  )

  const [login] = useMutation(
    gql`
      mutation login($input: UserLoginInput) {
        user(input: $input) @rest(path: "/auth/login", method: "POST")
      }
    `,
    mutationBaseOptions
  )

  const [validateExistingEmail] = useMutation(gql`
    mutation validateExistingEmail($input: String!) {
      validateExistingEmail(input: $input)
        @rest(path: "/auth/validate", method: "POST")
    }
  `)

  return { user, register, logout, update, login, validateExistingEmail }
}

export default useCurrentUser
