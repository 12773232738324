/*  eslint-disable */

import { useContext, useEffect } from "react"
import StateContext from "src/components/AirmasonNavigateMachine/StateContext"

import { actionTypes, FormContext, useFormState } from "../components/Form"
import useCurrentUser from "./useCurrentUser"
import useNavigateMachine from "./useNavigateMachine"

export const states = {
  "/": {
    on: {
      PLANS: "/plans",
      GET_STARTED: "/register",
    },
  },

  "/register": {
    onEnter: ({ plan, resetSelectedPlan, planSubmitted, user }) => {
      if (user && user.active) return "REDIRECT"

      if (!plan || !planSubmitted) {
        return "PLANS"
      }
    },
    on: {
      PLANS: "/plans",
      COMPLETE: "/redirect",
      default: ({ plan, planSubmitted, user, companySize }) => {
        return "COMPLETE"
      },
    },
  },
  "/plans": {
    onEnter: ({ user }) => {
      if (user && user.active) return "REDIRECT"
    },
    on: {
      REDIRECT: "/redirect",
      NO_USER: "/register",
      PAYMENT: "/payment",
      IS_ENTERPRISE: "/book-demo",
      REGISTER: "/register",
      default: ({ user, plan }) => {
        if (plan === "Enterprise") return "IS_ENTERPRISE"

        return "REGISTER"
      },
    },
  },
  "/payment": {
    context: () => {
      const { dispatch } = useContext(FormContext)
      const resetSelectedPlan = () =>
        dispatch({
          type: actionTypes.FORM_SUBMIT_RESET,
          formName: "plans",
        })
      return { resetSelectedPlan }
    },
    onEnter: ({ plan, resetSelectedPlan, planSubmitted, user }) => {
      if (!plan) {
        return "NO_PLAN"
      }
      if (user && user.active) {
        return "COMPLETE"
      }
      planSubmitted && resetSelectedPlan && resetSelectedPlan()
    },

    on: {
      NO_PLAN: "/plans",
      COMPLETE: "/redirect",
      BACK: "/plans",
      default: () => "COMPLETE",
    },
  },
  "/confirm": {
    on: {
      BACK: "/plans",
      NEXT: "/redirect",
    },
  },
  "/login": {
    onEnter: ({ user }) => {
      if (user && user.active) return "IS_CUSTOMER"
      if (user) return "IS_NOT_CUSTOMER"
    },
    on: {
      IS_CUSTOMER: "/redirect",
      IS_NOT_CUSTOMER: "/plans",
      default: ({ user }) => {
        if (user && user.user.active) return "IS_CUSTOMER"
        return "IS_NOT_CUSTOMER"
      },
    },
  },

  "/contact": {
    on: {
      BACK: "/plans",
    },
  },
  "/redirect": {},
  // Avoid losing query parameters on 3 forms
  "/forms/copywriting": {},
  "/forms/design": {},
  "/forms/integrations": {},
}

function useSelectedPlan() {
  const plansFormState = useFormState("plans")
  const plan =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.selectedPlan

  const submitted =
    plansFormState && plansFormState.meta && plansFormState.meta.submitted

  return [plan, submitted]
}

function useSelectedCompanySize() {
  const userFormState = useFormState("user")

  const companySize =
    userFormState && userFormState.values && userFormState.values.company_size

  return companySize
}

const useAirmasonNavigateMachine = ({ pathname }) => {
  const [plan, planSubmitted] = useSelectedPlan()
  const { user } = useCurrentUser()

  // industry: user.industry,
  useEffect(() => {
    if (user) {
      if (window.analytics) {
        window.analytics.identify(user.id, {
          firstName: user.first_name,
          lastName: user.last_name,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone: user.contact_number,
          company_size_range: user.company_size,
          acticve: user.active,
        })
      }

      window.$FPROM &&
        window.$FPROM.trackSignup({
          email: user.email,
          uid: user.id,
        })
    }
  }, [user])

  const stateContext = useContext(StateContext)

  const context = { ...stateContext, plan, planSubmitted, user }

  return useNavigateMachine({ states, pathname, context })
}

export default useAirmasonNavigateMachine
